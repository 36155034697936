import {
  Box,
  Container,
  Unstable_Grid2 as Grid,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import CopyableTextField from "../../components/copyableTextField";
import { Layout } from "../../components/layout";
import { OverviewTotalCustomers } from "../../components/overview/overview-customers";
import { OverviewServer } from "../../components/overview/overviewServer";
import useAuth from "../../hooks/useAuth";
import { recuperarServidorStatus } from "../../services/status";
import { recuperarStatus } from "../../services/usuario";
import { StatusCount, ServidorStatus } from "../../types";

const HomeAdmin: FC = () => {
  const navigate = useNavigate();
  const { user, signout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingServidorStatus, setIsLoadingServidorStatus] = useState(false);
  const [usuariosOnline, setUsuariosOnline] = useState<StatusCount>();
  const [serverStatus, setServerStatus] = useState<ServidorStatus[]>([]);

  const fetchStatus = async (id: string) => {
    try {
      setIsLoading(true);
      const response = await recuperarStatus(id);
      if (response && response.data) {
        setUsuariosOnline(response.data);
      }
    } catch (error) {
      toast.error("Erro ao recuperar todos os usuários:" + error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchServidorStatus = async (id: string) => {
    try {
      setIsLoadingServidorStatus(true);
      const response = await recuperarServidorStatus(id);
      if (response && response.data) {
        setServerStatus(response.data);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        toast.info("Desconectado por inatividade");
        signout();
        navigate("/");
      } else {
        toast.error("Erro ao recuperar status do servidor:" + error);
      }
    } finally {
      setIsLoadingServidorStatus(false);
    }
  };

  useEffect(() => {
    if (user?.idUsuario) {
      fetchStatus(user.idUsuario);
      fetchServidorStatus(user.idUsuario);
    }
  }, [user]);

  const handleRefresh = () => {
    if (user?.idUsuario) {
      fetchServidorStatus(user.idUsuario);
    }
  };

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            {user?.tipo === "ADMIN" && (
              <>
                <Grid xs={12} sm={12} lg={12}>
                  <CopyableTextField />
                </Grid>
                {/* <Grid xs={12} sm={6} lg={3}>
                  <Tooltip
                    title="Usuários online nas CSPs."
                    arrow
                    key={`tooltip-online`}
                  >
                    <div style={{ height: "100%" }}>
                      <OverviewTotalCustomers
                        title="Online"
                        bgColor="text.disabled"
                        sx={{ height: "100%" }}
                        value={
                          isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            usuariosOnline?.usuariosOnline ?? "0"
                          )
                        }
                      />
                    </div>
                  </Tooltip>
                </Grid> */}
                <Grid xs={12} sm={6} lg={3}>
                  <Tooltip
                    title="Quantidade de conexões de usuários com Data de Vencimento maior que hoje e não estejam bloqueados."
                    arrow
                    key={`tooltip-ativos-conexao`}
                  >
                    <div style={{ height: "100%" }}>
                      <OverviewTotalCustomers
                        title="Ativos Conexão"
                        bgColor="success.main"
                        sx={{ height: "100%" }}
                        value={
                          isLoading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : (
                            usuariosOnline?.usuariosAtivosPorConexao ?? "0"
                          )
                        }
                      />
                    </div>
                  </Tooltip>
                </Grid>
              </>
            )}
            <Grid xs={12} sm={6} lg={3}>
              <Tooltip
                title="Usuários com Data de Vencimento maior ou igual a hoje e que não estejam vencidos. (Considera bloqueados)"
                arrow
                key={`tooltip-ativos`}
              >
                <div style={{ height: "100%" }}>
                  <OverviewTotalCustomers
                    title="Ativos Conexão Vencimento"
                    bgColor="text.secondary"
                    sx={{ height: "100%" }}
                    value={
                      isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        usuariosOnline?.usuariosAtivosConexaoVencimento ?? "0"
                      )
                    }
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <Tooltip
                title="Usuários com Data de Vencimento menor que hoje."
                arrow
                key={`tooltip-vencidos`}
              >
                <div style={{ height: "100%" }}>
                  <OverviewTotalCustomers
                    title="Vencidos"
                    bgColor="error.main"
                    sx={{ height: "100%" }}
                    value={
                      isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        usuariosOnline?.usuariosVencidos ?? "0"
                      )
                    }
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <Tooltip
                title="Usuários que estejam bloqueados."
                arrow
                key={`tooltip-bloqueados`}
              >
                <div style={{ height: "100%" }}>
                  <OverviewTotalCustomers
                    title="Bloqueados"
                    bgColor="info.main"
                    sx={{ height: "100%" }}
                    value={
                      isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        usuariosOnline?.usuariosBloqueados ?? "0"
                      )
                    }
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <Tooltip
                title="Usuários Teste com data de vencimento maior que hoje."
                arrow
                key={`tooltip-teste`}
              >
                <div style={{ height: "100%" }}>
                  <OverviewTotalCustomers
                    title="Teste"
                    bgColor="primary.main"
                    sx={{ height: "100%" }}
                    value={
                      isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        usuariosOnline?.usuariosTeste ?? "0"
                      )
                    }
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <Tooltip
                title="Revendas com Data de Vencimento menor que hoje."
                arrow
                key={`tooltip-revendas-vencidas`}
              >
                <div style={{ height: "100%" }}>
                  <OverviewTotalCustomers
                    title="Revendas Vencidas"
                    bgColor="warning.main"
                    sx={{ height: "100%" }}
                    value={
                      isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        usuariosOnline?.revendasVencidas ?? "0"
                      )
                    }
                  />
                </div>
              </Tooltip>
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <Tooltip
                title="Revendas que estejam bloqueadas."
                arrow
                key={`tooltip-revendas-bloqueadas`}
              >
                <div style={{ height: "100%" }}>
                  <OverviewTotalCustomers
                    title="Revendas Bloqueadas"
                    bgColor="secondary.main"
                    sx={{ height: "100%" }}
                    value={
                      isLoading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        usuariosOnline?.revendasBloqueadas ?? "0"
                      )
                    }
                  />
                </div>
              </Tooltip>
            </Grid>
            {isLoadingServidorStatus ? (
              <Grid xs={12} sm={12} lg={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box mb={2}>Carregando servidores</Box>
                  <CircularProgress size={24} color="inherit" />
                </Box>
              </Grid>
            ) : (
              serverStatus.map((server, index) => (
                <Grid xs={12} sm={12} lg={6} key={`server-status-${index}`}>
                  <OverviewServer
                    servidor={server?.servidor ?? "Carregando..."}
                    url={server?.url ?? "Carregando..."}
                    porta={server?.porta ?? "Carregando..."}
                    status={server?.status ?? "Carregando..."}
                    sx={{ height: "100%" }}
                    onRefresh={handleRefresh}
                    isLoading={isLoadingServidorStatus}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default HomeAdmin;
